import PropTypes from 'prop-types'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {Checkbox as VisualCheckbox, HelperText} from 'uikit'


const Checkbox = ({name, value, label, helperText, required, fieldProps, ...props}) => {
  const {register} = useFormContext()

  return (
    <>
      <VisualCheckbox
          name={name}
          label={label}
          value={value}
          required={required}
          helperText={helperText}
          checked={value}
          {...register(name, fieldProps)}
          {...props}
      />
      <ErrorMessage as={HelperText} name={name} state="invalid" />
    </>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  fieldProps: PropTypes.object,
}

export default Checkbox
