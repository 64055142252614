import PropTypes from 'prop-types'
import {If} from 'babel-plugin-jsx-control-statements'
import {ContentContainer, PaymentPageWrapper, Button, FormContainer, Flex, Paper, Text} from 'uikit'
import * as yup from 'yup'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useTranslation} from 'react-i18next'
import AppShell from '../../components/AppShell'
import {INITIAL_CONSENT} from '../../hooks/useConsent'
import TextInput from '../form/TextInput'
import Checkbox from '../form/Checkbox'


const schema = (t) => yup.object().shape({
  email: yup.string().email(t('validation.email')).required(t('validation.required')),
  personalInformation: yup.bool().oneOf([true], t('validation.required')),
})

const PayForm = ({pageData, isLoading, onSubmit}) => {
  const {t} = useTranslation()
  const form = useForm({
    defaultValues: {
      cookies: INITIAL_CONSENT.analyticalCookies && INITIAL_CONSENT.marketingCookies,
    },
    resolver: yupResolver(schema(t)),
  })

  return (
    <AppShell brand={pageData?.brand}>
      <ContentContainer>
        <PaymentPageWrapper>
          <Flex column>
            <Flex column crossAxis="center">
              <Paper>
                <Text component="h2" textStyle="h4" align="center">{pageData?.title}</Text>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{__html: pageData?.content?.data?.childMarkdownRemark?.html}} />
              </Paper>
            </Flex>
            <FormProvider {...form}>
              <Flex column as="form" onSubmit={form.handleSubmit(onSubmit)}>
                <FormContainer>
                  <If condition={pageData.firstNameOptions.show}>
                    <TextInput
                        name="firstName"
                        label={t('field.first_name')}
                        required={pageData.firstNameOptions.required}
                    />
                  </If>
                  <If condition={pageData.lastNameOptions.show}>
                    <TextInput
                        name="lastName"
                        label={t('field.last_name')}
                        required={pageData.lastNameOptions.required}
                    />
                  </If>
                  <If condition={pageData.cityOptions.show}>
                    <TextInput
                        name="city"
                        label={t('field.city')}
                        required={pageData.cityOptions.required}
                    />
                  </If>
                  <If condition={pageData.postCodeOptions.show}>
                    <TextInput
                        name="postCode"
                        label={t('field.post_code')}
                        required={pageData.postCodeOptions.required}
                    />
                  </If>
                  <If condition={pageData.addressOptions.show}>
                    <TextInput
                        name="address"
                        label={t('field.address')}
                        required={pageData.addressOptions.required}
                    />
                  </If>
                  <If condition={pageData.phoneOptions.show}>
                    <TextInput
                        name="phone"
                        label={t('field.phone')}
                        required={pageData.phoneOptions.required}
                    />
                  </If>
                  <If condition={pageData.emailOptions.show}>
                    <TextInput
                        name="email"
                        label={t('field.email')}
                        required={pageData.emailOptions.required}
                    />
                  </If>
                </FormContainer>
                <Flex mainAxis="center">
                  <Flex column>
                    <Checkbox
                        inverted
                        name="personalInformation"
                        label={t('field.personal_information')}
                    />
                    <Checkbox
                        inverted
                        name="cookies"
                        label={t('field.cookies')}
                    />
                  </Flex>
                </Flex>
                <Flex mainAxis="center">
                  <Button type="submit" disabled={isLoading}>{t('form.continue')}</Button>
                </Flex>
              </Flex>
            </FormProvider>
          </Flex>
        </PaymentPageWrapper>
      </ContentContainer>
    </AppShell>
  )
}

PayForm.propTypes = {
  pageData: PropTypes.object,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
}

export default PayForm
