import PropTypes from 'prop-types'
import {ContentContainer, PaymentPageWrapper, Button, Flex, Paper, Text, Loader} from 'uikit'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {If} from 'babel-plugin-jsx-control-statements'
import {useEffect, useState} from 'react'
import {navigate} from 'gatsby'
import * as payoutCheckoutStatuses from '../../../admin/constrants/payoutCheckoutStatuses'
import {sleep} from '../../utils/api'
import AppShell from '../AppShell'


const maxAttempts = 4

const PaymentResult = ({externalId, pageData, params}) => {
  const {language: currentLang} = useI18next()
  const {t} = useTranslation()
  const [state, setState] = useState(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const checkOrder = async () => {
        let attempt = 0
        while (attempt < maxAttempts) {
          try {
            // eslint-disable-next-line no-process-env
            const res = await window.fetch(`${process.env.GATSBY_API_URL}/api/orders/${externalId}`, {
              method: 'GET',
            })
              .then((res) => res.json())
            if (res.state === payoutCheckoutStatuses.PROCESSING) throw new Error('Is pending')
            setState(res.state)
            break
          } catch (_e) {
            await sleep(Math.min(2 ** attempt * 1000, 10_000))
            attempt += 1
          }
        }

        if (attempt >= maxAttempts) {
          setState(payoutCheckoutStatuses.EXPIRED)
        }
      }

      checkOrder()
    }
  }, [externalId])

  const handleRepeatPayment = () => {
    navigate(`${pageData.slug}/${params.uuid}`)
  }

  if (!state || state === payoutCheckoutStatuses.PROCESSING) {
    return (
      <AppShell brand={pageData?.brand}>
        <ContentContainer>
          <PaymentPageWrapper>
            <Flex column>
              <Flex column crossAxis="center">
                <Paper>
                  <Text component="h2" textStyle="h4" align="center">{t('payment.loading')}</Text>
                  <Loader />
                </Paper>
              </Flex>
            </Flex>
          </PaymentPageWrapper>
        </ContentContainer>
      </AppShell>
    )
  }

  if (state === payoutCheckoutStatuses.SUCCEEDED) {
    return (
      <AppShell brand={pageData?.brand}>
        <ContentContainer>
          <PaymentPageWrapper>
            <Flex column>
              <Flex column crossAxis="center">
                <Paper>
                  <Text component="h2" textStyle="h4" align="center">{t('payment.success')}</Text>
                  <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{__html: pageData.thankYouContent.data.childMarkdownRemark.html}}
                  />
                </Paper>
              </Flex>
              <If condition={pageData.ThankYouButton?.text}>
                <Flex mainAxis="center" crossAxis="end" height="7rem">
                  <Button
                      as="a"
                      href={pageData.ThankYouButton?.link}
                      language={currentLang}
                  >
                    {pageData.ThankYouButton?.text}
                  </Button>
                </Flex>
              </If>
            </Flex>
          </PaymentPageWrapper>
        </ContentContainer>
      </AppShell>
    )
  }

  return (
    <AppShell brand={pageData?.brand}>
      <ContentContainer>
        <PaymentPageWrapper>
          <Flex column>
            <Flex column crossAxis="center">
              <Paper>
                <Text component="h2" textStyle="h4" align="center">{t('payment.failed')}</Text>
                <div />
              </Paper>
              <Button onClick={handleRepeatPayment}>{t('back')}</Button>
            </Flex>
          </Flex>
        </PaymentPageWrapper>
      </ContentContainer>
    </AppShell>
  )
}

PaymentResult.propTypes = {
  externalId: PropTypes.string,
  pageData: PropTypes.object,
  params: PropTypes.object,
}

export default PaymentResult
