import {useState} from 'react'
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash-es'
import {ContentContainer, PaymentPageWrapper, Button, TextInput, Flex, Paper} from 'uikit'
import {useTranslation} from 'react-i18next'
import {If} from 'babel-plugin-jsx-control-statements'
import AppShell from '../../components/AppShell'
import Packages from '../sections/Packages'


const Paywall = ({pageData, isLoading, setLoading, onSubmit}) => {
  const [activePackage, setActivePackage] = useState()
  const [customFieldValue, setCustomFieldValue] = useState()
  const [packages, setPackages] = useState(pageData?.packages)
  const {t} = useTranslation()

  const handleDiscountSubmit = (e) => {
    e.preventDefault()
    if (typeof window !== 'undefined') {
      setLoading(true)
      const code = new window.FormData(e.target).get('discountCode')
      if (!isEmpty(code)) {
        // eslint-disable-next-line no-process-env
        return window.fetch(`${process.env.GATSBY_API_URL}/api/discount-codes/${code}`)
          .then((res) => res.json())
          .then((res) => {
            setPackages([...res, ...pageData?.packages])
            setLoading(false)
          }).catch(() => {
            setPackages(pageData?.packages)
            setLoading(false)
          })
      }
      setLoading(false)
    }
  }

  return (
    <AppShell brand={pageData?.brand}>
      <ContentContainer>
        <PaymentPageWrapper>
          <Flex column>
            <Flex column crossAxis="center">
              <Paper>
                <div
                  // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{__html: pageData.paywallContent.data.childMarkdownRemark.html}}
                />
              </Paper>
            </Flex>
            <form onSubmit={handleDiscountSubmit}>
              <Flex mainAxis="center" crossAxis="center" height="10rem">
                <TextInput name="discountCode" label={t('field.discount_code')} />
                <Button
                    type="submit"
                    disabled={isLoading}
                >
                  {t('form.apply')}
                </Button>
              </Flex>
            </form>
            <Packages packages={packages} activePackage={activePackage} setActivePackage={setActivePackage} />
            <If condition={pageData?.CustomField?.visibility}>
              <Flex mainAxis="center" crossAxis="center" height="10rem">
                <TextInput
                    name="customField"
                    label={pageData.CustomField.label}
                    onChange={(e) => setCustomFieldValue(e.target.value)}
                />
              </Flex>
            </If>
            <Flex mainAxis="center">
              <Button onClick={() => onSubmit(activePackage, customFieldValue)} disabled={isLoading}>
                {t('pay')}
              </Button>
            </Flex>
          </Flex>
        </PaymentPageWrapper>
      </ContentContainer>
    </AppShell>
  )
}

Paywall.propTypes = {
  pageData: PropTypes.object,
  isLoading: PropTypes.bool,
  setLoading: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default Paywall
