/**
 *  Enumeration for checkout statuses in Payout API
 */

export const PROCESSING = 'processing' // Created payment form
export const REQUIRES_CAPTURE = 'requires_capture' // Additional authorization requested
export const SUCCEEDED = 'succeeded' // From checkout created transaction
export const EXPIRED = 'expired' // No transaction has been created. Expires 10 days after creation

export const ALL_CHECKOUT_STATUS = [
  PROCESSING,
  REQUIRES_CAPTURE,
  SUCCEEDED,
  EXPIRED,
]
