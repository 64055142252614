import PropTypes from 'prop-types'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {TextInput as VisualTextInput, HelperText} from 'uikit'


const TextInput = ({name, label, helperText, required, fieldProps, ...props}) => {
  const {register, getFieldState} = useFormContext()
  const {invalid} = getFieldState(name)
  return (
    <div>
      <VisualTextInput
          name={name}
          state={invalid ? 'invalid' : 'valid'}
          label={label}
          helperText={invalid ? helperText : ''}
          required={required}
          {...register(name, fieldProps)}
          {...props}
      />
      <ErrorMessage as={HelperText} name={name} state="invalid" />
    </div>
  )
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  fieldProps: PropTypes.object,
}

export default TextInput
